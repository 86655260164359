<template>
  <div>
    <Loading
      :active.sync="this.isLoading"
      :is-full-page="true"
      :can-cancel="true"
    ></Loading>
    <!-- <b-alert
      show
      variant="danger"
      class="alert alert-custom alert-close alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-white svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Roller.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>{{ $t("MESSAGES.BETA_TITLE") }}</b>
        {{ $t("MESSAGES.BETA_TEXT") }}
      </div>
    </b-alert> -->
    <b-alert
      show
      variant="info"
      class="alert alert-custom alert-dark alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-white svg-icon-lg">
          <inline-svg src="/media/svg/icons/Home/Timer.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>{{ $t("DASHBOARD.DATA_CALCULATION") }}</b>
        {{ $t("DASHBOARD.DATA_CALCULATION_NOTIFICATION") }}.
        <b>{{ this.latestUpdate }}</b>
      </div>
    </b-alert>
    <div class="row" v-if="isAuthenticated">
      <div class="col-xl-12">
        <!--begin::Tiles Widget 22-->
        <div
          class="card card-custom bgi-no-repeat gutter-b bg-secondary"
          style="
            height: 250px;
            background-position: calc(100% + 0.5rem) calc(100% + 0.5rem);
            background-size: 100% auto;
            background-image: url(/media/svg/patterns/rhone-2.svg);
          "
        >
          <!--begin::Body-->
          <div class="card-body">
            <h3 class="text-dark font-weight-bolder">
              {{ $t("DASHBOARD.DOWNLOAD_TITLE") }}
            </h3>
            <p class="text-dark font-size-lg mt-5 mb-10">
              {{ $t("DASHBOARD.DOWNLOAD_DESCRIPTION") }}
            </p>
            <a
              @click="downloadDashboard"
              class="btn btn-link btn-link-warning font-weight-bold"
              >{{ $t("DASHBOARD.DOWNLOAD_LINK") }}
              <span class="svg-icon svg-icon-lg svg-icon-warning">
                <inline-svg
                  src="/media/svg/icons/Navigation/Arrow-right.svg"
                /> </span
            ></a>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Tiles Widget 22-->
      </div>
    </div>
    <!--begin::Dashboard-->
    <div class="row" v-if="!this.isAuthenticated">
      <div class="col-xxl-12 order-1 order-xxl-1">
        <Maps
          v-if="
            this.getDashboardCardStatus('MapSebaranWilayah')
              ? this.getDashboardCardStatus('MapSebaranWilayah').status
              : false
          "
        ></Maps>
      </div>
    </div>
    <div class="row" v-if="this.isAuthenticated">
      <div class="col-xxl-9 order-1 order-xxl-1">
        <Maps
          v-if="
            this.getDashboardCardStatus('MapSebaranWilayah')
              ? this.getDashboardCardStatus('MapSebaranWilayah').status
              : false
          "
        ></Maps>
      </div>
      <div class="col-xxl-3 order-2 order-xxl-2">
        <DashboardFilter
          v-if="
            this.getDashboardCardStatus('MapSebaranWilayah')
              ? this.getDashboardCardStatus('MapSebaranWilayah').status
              : false
          "
        ></DashboardFilter>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-6 order-1 order-xxl-1">
        <keep-alive>
          <YearlyChart
            v-if="
              this.getDashboardCardStatus('StatistikKasus')
                ? this.getDashboardCardStatus('StatistikKasus').status
                : false
            "
          ></YearlyChart>
        </keep-alive>
      </div>
      <div class="col-xxl-6 order-2 order-xxl-2">
        <MonthlyChart
          v-if="
            this.getDashboardCardStatus('StatisticKasusPerBulan')
              ? this.getDashboardCardStatus('StatisticKasusPerBulan').status
              : false
          "
        ></MonthlyChart>
      </div>
    </div>
    <div class="row">
      <RightClassification
        class="col-xxl-4 order-1 order-xxl-1"
        v-if="
          this.getDashboardCardStatus('klasifikasiHak')
            ? this.getDashboardCardStatus('klasifikasiHak').status
            : false
        "
      ></RightClassification>

      <Issue
        class="col-xxl-4 order-1 order-xxl-1"
        v-if="
          this.getDashboardCardStatus('isu')
            ? this.getDashboardCardStatus('isu').status
            : false
        "
      ></Issue>

      <ClaimantClassification
        class="col-xxl-4 order-1 order-xxl-1"
        v-if="
          this.getDashboardCardStatus('kladifikasiPengadu')
            ? this.getDashboardCardStatus('kladifikasiPengadu').status
            : false
        "
      ></ClaimantClassification>

      <VictimClassification
        class="col-xxl-4 order-1 order-xxl-1"
        v-if="
          this.getDashboardCardStatus('klasifikasiKorban')
            ? this.getDashboardCardStatus('klasifikasiKorban').status
            : false
        "
      ></VictimClassification>

      <DefendantClassification
        class="col-xxl-4 order-1 order-xxl-1"
        v-if="
          this.getDashboardCardStatus('klasifikasiPihakYangDiadukan')
            ? this.getDashboardCardStatus('klasifikasiPihakYangDiadukan').status
            : false
        "
      ></DefendantClassification>

      <SubmissionMethod
        class="col-xxl-4 order-1 order-xxl-1"
        v-if="
          this.getDashboardCardStatus('caraPenyampaianBerkas')
            ? this.getDashboardCardStatus('caraPenyampaianBerkas').status
            : false
        "
      ></SubmissionMethod>

      <CaseDetail
        class="col-xxl-4 order-1 order-xxl-1"
        v-if="
          this.getDashboardCardStatus('detailKasus')
            ? this.getDashboardCardStatus('detailKasus').status
            : false
        "
      ></CaseDetail>

      <SpecialEvent
        class="col-xxl-4 order-1 order-xxl-1"
        v-if="
          this.getDashboardCardStatus('KejadianKhusus')
            ? this.getDashboardCardStatus('KejadianKhusus').status
            : false
        "
      ></SpecialEvent>

      <CaseByCountry
        class="col-xxl-4 order-1 order-xxl-1"
        v-if="
          this.getDashboardCardStatus('caseByCountry')
            ? this.getDashboardCardStatus('caseByCountry').status
            : false
        "
      ></CaseByCountry>
    </div>
    <div class="row"></div>
    <b-modal
      ref="my-modal"
      centered
      hide-header
      body-bg-variant="primary"
      body-text-variant="light"
      footer-bg-variant="info"
      footer-text-variant="light"
      size="xl"
    >
      <div class="d-block text-center">
        <h3>
          {{ $t("MESSAGES.POP_UP_WELCOME") }}
        </h3>
        <h1 class="text-dark-75 text-lg-body-2">
          {{ $t("MESSAGES.POP_UP_WELCOME_FREE") }}
        </h1>
        <h3>{{ $t("MESSAGES.POP_UP_WELCOME_SURVEY") }}</h3>
      </div>
      <template #modal-footer="{ close }">
        <b
          >{{ $t("MESSAGES.POP_UP_WELCOME_SURVEY_ALL")
          }}<a href="#" @click="showSurvey()">{{
            $t("MESSAGES.POP_UP_WELCOME_SURVEY_HERE")
          }}</a></b
        >
        <b-button size="lg" variant="danger" @click="close()">
          {{ $t("MESSAGES.POP_UP_WELCOME_SURVEY_CLOSE") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      ref="my-survey"
      centered
      title="Survey"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="primary"
      body-text-variant="light"
      hide-footer
      size="xl"
    >
      <div class="d-block text-center">
        <b-form @submit="onSubmit" @reset="onReset">
          <b-container fluid>
            <div class="row">
              <div class="col-md-12">
                <b-alert :show="show_alert" variant="warning">{{
                  error_surver
                }}</b-alert>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-5">
                    <b-form-group
                      id="input-group-1"
                      class="row"
                      :label="$t('SURVEY.EMAIL.LABEL')"
                      label-for="input-1"
                      label-class="text-white"
                      label-cols="2"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="form.email"
                        type="email"
                        required
                        placeholder="Enter email"
                      ></b-form-input>
                    </b-form-group>
                    <div class="form-group text-left">
                      <fieldset aria-describedby="" class="form-group">
                        <legend
                          tabindex="-1"
                          class="bv-no-focus-ring col-form-label pt-0"
                        >
                          <h3>
                            <u>{{ $t("SURVEY.PENGGUNAAN_APLIKASI.LABEL") }}</u>
                          </h3>
                        </legend>
                        <div
                          tabindex="-1"
                          role="group"
                          class="bv-no-focus-ring"
                        >
                          <div
                            role="radiogroup"
                            tabindex="-1"
                            class="bv-no-focus-ring"
                          >
                            <div
                              class="
                                custom-control
                                custom-control-inline
                                custom-radio
                              "
                            >
                              <input
                                type="radio"
                                name="kepuasan"
                                v-model="form.kepuasan"
                                class="custom-control-input"
                                value="1"
                                id="rpuas"
                                required
                              />
                              <label
                                class="custom-control-label text-light"
                                for="rpuas"
                              >
                                <span>
                                  <img
                                    src="media/image/01.png"
                                    style="width: 100px"
                                  /><br />
                                  {{
                                    $t("SURVEY.PENGGUNAAN_APLIKASI.OPTLABEL1")
                                  }}
                                </span>
                              </label>
                            </div>
                            <div
                              class="
                                custom-control
                                custom-control-inline
                                custom-radio
                              "
                            >
                              <input
                                type="radio"
                                name="kepuasan"
                                v-model="form.kepuasan"
                                class="custom-control-input text-light"
                                value="2"
                                id="rnormal"
                                required
                              />
                              <label
                                class="custom-control-label text-white"
                                for="rnormal"
                              >
                                <span>
                                  <img
                                    src="media/image/Neutral.png"
                                    style="width: 100px"
                                  /><br />
                                  {{
                                    $t("SURVEY.PENGGUNAAN_APLIKASI.OPTLABEL2")
                                  }}
                                </span>
                              </label>
                            </div>
                            <div
                              class="
                                custom-control
                                custom-control-inline
                                custom-radio
                              "
                            >
                              <input
                                type="radio"
                                name="kepuasan"
                                v-model="form.kepuasan"
                                class="custom-control-input text-light"
                                value="0"
                                id="rtidakpuas"
                                required
                              />
                              <label
                                class="custom-control-label text-white"
                                for="rtidakpuas"
                              >
                                <span>
                                  <img
                                    src="media/image/02.png"
                                    style="width: 100px"
                                  /><br />
                                  {{
                                    $t("SURVEY.PENGGUNAAN_APLIKASI.OPTLABEL3")
                                  }}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-7 text-left">
                    <b-form-group :label="$t('SURVEY.JENISKELAMIN.LABEL')">
                      <b-form-radio-group
                        v-model="form.gender"
                        :options="optgender"
                        name="gender"
                        required
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-group :label="$t('SURVEY.USIA.LABEL')">
                      <b-form-radio-group
                        v-model="form.age"
                        :options="optage"
                        class="text-light"
                        name="age"
                        required
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-group :label="$t('SURVEY.PENDIDIKAN.LABEL')">
                      <b-form-radio-group
                        v-model="form.education"
                        :options="opteducation"
                        class="text-light"
                        name="education"
                        required
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-group
                      id="input-group-1"
                      class="row"
                      :label="$t('SURVEY.SARAN.LABEL')"
                      label-for="input-2"
                      label-class="text-white"
                      label-cols="3"
                    >
                      <b-form-textarea
                        id="advice"
                        v-model="form.advice"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row"><br /></div>
            <div class="row">
              <div class="col-md-12 text-right">
                <b-button type="submit" class="mr-3" variant="warning">
                  {{ $t("SURVEY.BTN.LABEL") }}</b-button
                >
              </div>
            </div>
          </b-container>
        </b-form>
      </div>
    </b-modal>

    <!--end::Dashboard-->
  </div>
</template>
<style>
.custom-control-label {
  color: white !important;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_CASE_DETAIL,
  GET_CLAIMANT_CLASSIFICATION,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_MAPS_DATA,
  GET_DASHBOARD_MONTHLY_CASE,
  GET_DASHBOARD_TOTAL_CASE,
  GET_DASHBOARD_YEARLY_CASE,
  GET_DEFENDANT_CLASSIFICTION,
  GET_ISSUE_CASE,
  GET_LATEST_UPDATE,
  GET_RIGHT_CLASSIFICATION_CASE,
  GET_SPECIAL_EVENT_CASE,
  GET_SUBMISSION_METHOD_CASE,
  GET_VICTiM_CLASSIFICATION,
  GET_CASE_BY_COUNTRY,
  SET_LATEST_UPDATE,
  SURVEY_POST,
  LANDING_PAGE,
} from "@/core/services/store/dashboard.module";
import { mapGetters } from "vuex";
import Maps from "@/view/content/widgets/Maps.vue";
import YearlyChart from "@/view/content/widgets/stats/YearlyChart";
import RightClassification from "@/view/content/widgets/list/RightClassification";
import Issue from "@/view/content/widgets/list/Issue";
import ClaimantClassification from "@/view/content/widgets/list/ClaimantClassification";
import VictimClassification from "@/view/content/widgets/list/VictimClassification";
import DefendantClassification from "@/view/content/widgets/list/DefendantClassification";
import MonthlyChart from "@/view/content/widgets/stats/MonthlyChart";
import SubmissionMethod from "@/view/content/widgets/list/SubmissionMethod";
import CaseDetail from "@/view/content/widgets/list/CaseDetail";
import SpecialEvent from "@/view/content/widgets/list/SpecialEvent";
import DashboardFilter from "@/view/content/widgets/DashboardFilter";
import CaseByCountry from "@/view/content/widgets/list/CaseByCountry";
import * as axios from "axios";
import Swal from 'sweetalert2'
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { SET_LOADING } from "@/core/services/store/loading.module";

export default {
  name: "dashboard",
  components: {
    DashboardFilter,
    SpecialEvent,
    CaseDetail,
    SubmissionMethod,
    DefendantClassification,
    VictimClassification,
    ClaimantClassification,
    Issue,
    RightClassification,
    Maps,
    YearlyChart,
    MonthlyChart,
    CaseByCountry,
    Loading,
  },
  data() {
    return {
      latestUpdate: Date.now(),
      bodyBgVariant: "dark",
      footerTextVariant: "dark",
      optgender: [
        { text: this.$t("SURVEY.JENISKELAMIN.OPTLABEL1"), value: 1 },
        { text: this.$t("SURVEY.JENISKELAMIN.OPTLABEL2"), value: 2 },
      ],
      optage: [
        { text: this.$t("SURVEY.USIA.OPTLABEL1"), value: 1 },
        { text: this.$t("SURVEY.USIA.OPTLABEL2"), value: 2 },
        { text: this.$t("SURVEY.USIA.OPTLABEL3"), value: 3 },
        { text: this.$t("SURVEY.USIA.OPTLABEL4"), value: 4 },
      ],
      opteducation: [
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL1"), value: 1 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL2"), value: 2 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL3"), value: 3 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL4"), value: 4 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL5"), value: 5 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL6"), value: 6 },
        { text: this.$t("SURVEY.PENDIDIKAN.OPTLABEL7"), value: 7 },
      ],
      form: {
        email: "",
        gender: "",
        age: "",
        education: "",
        advice: "",
        kepuasan: "",
        fullPage: true,
      },
      error_surver: "",
      show_alert: false,
      isLoading: false,
    };
  },
  beforeMount() {
    this.$store.dispatch(SET_LOADING, true);
    this.$store.dispatch(GET_DASHBOARD_MAPS_DATA);
    this.$store.dispatch(GET_DASHBOARD_DATA);
    this.$store.dispatch(GET_DASHBOARD_TOTAL_CASE);
    this.$store.dispatch(GET_DASHBOARD_YEARLY_CASE);
    this.$store.dispatch(GET_RIGHT_CLASSIFICATION_CASE);
    this.$store.dispatch(GET_ISSUE_CASE);
    this.$store.dispatch(GET_CLAIMANT_CLASSIFICATION);
    this.$store.dispatch(GET_VICTiM_CLASSIFICATION);
    this.$store.dispatch(GET_DEFENDANT_CLASSIFICTION);
    this.$store.dispatch(GET_DASHBOARD_MONTHLY_CASE);
    this.$store.dispatch(GET_SUBMISSION_METHOD_CASE);
    this.$store.dispatch(GET_CASE_DETAIL);
    this.$store.dispatch(GET_SPECIAL_EVENT_CASE);
    this.$store.dispatch(GET_CASE_BY_COUNTRY);
    this.$store.dispatch(GET_LATEST_UPDATE);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.COMPLAINT_DATA"), route: "dashboard" },
      { title: this.$t("MENU.COMPLAINT_DASHBOARD") }
    ]);
    // if (!this.isAuthenticated) this.checkLandingPage();
    // this.$store.dispatch(SET_LOADING, false);
    this.getLatestUpdateInfo();
  },
  methods: {
    showModal() {
      if (this.$session.get("IsSurvey") === undefined) {
        this.$refs["my-modal"].show();
      }
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    showSurvey() {
      this.$refs["my-modal"].hide();
      // this.$refs["my-survey"].show();
      this.$router.push("survey");
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    getLatestUpdateInfo() {
      this.latestUpdate = new Date(this.getLatestUpdate).toLocaleString();
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === SET_LATEST_UPDATE) {
          this.latestUpdate = new Date(this.getLatestUpdate).toLocaleString();
        }
        if (mutation.type === SET_LOADING) {
          this.isLoading = this.getLoadingState;
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
    },
    downloadDashboard() {
      this.$store.dispatch(SET_LOADING, true);
      axios({
        url:
          process.env.VUE_APP_API_BASE_URL + "api/smartmap/download_dashboard",
        method: "POST",
        data: this.getPostParams,
        responseType: "blob",
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .finally(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      const email = this.form.email;
      const values = this.form.kepuasan;
      const gender = this.form.gender;
      const age = this.form.age;
      const education = this.form.education;
      const advice = this.form.advice;

      this.$store
        .dispatch(SURVEY_POST, {
          email,
          values,
          gender,
          age,
          education,
          advice,
        })
        .then(() => {
          this.$session.set("IsSurvey", "true");
          this.$refs["my-survey"].hide();
          this.show_alert = false;
          Swal.fire(
              'Survey',
              'Survey successfully registered',
              'success'
          );
        })
        .catch((error) => {
          console.log(error.response);
          let keys = Object.keys(error.response.data.errors);
          let keysval = Object.values(error.response.data.errors);
          for (let i = 0; i < keys.length; i++) {
            this.error_surver = keysval[i][0];
            this.show_alert = true;
          }
        });
    },
    checkLandingPage() {
      var SurveyEnable = false;
      this.$store.dispatch(LANDING_PAGE).then((val) => {
        const surveyState = val.data;
        for (var i = 0; i < surveyState.length; i++) {
          if (
            surveyState[i].config_name == "Survey" &&
            surveyState[i].config_state == true
          ) {
            SurveyEnable = true;
            break;
          }
        }
        if (SurveyEnable) {
          this.showModal();
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      "getDashboardCardStatus",
      "getSelectedYear",
      "getSelectedMonth",
      "getSelectedProvince",
      "getLatestUpdate",
      "isAuthenticated",
      "getPostParams",
      "getLoadingState",
    ]),
  },
};
</script>
