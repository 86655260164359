<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t("SUBMISSION_METHOD.TITLE") }}
          </span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm"> </span>
        </h3>
      </div>
      <div class="card-body pt-3 pb-0">
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th class="p-0" style="width: 50px"></th>
                <th class="p-0" style="min-width: 190px"></th>
                <th class="p-0" style="min-width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in this.list.slice(0, this.limit)">
                <tr v-bind:key="i">
                  <td class="pl-0 py-4">
                    <div class="symbol symbol-50 symbol-light mr-1">
                      <span class="symbol-label">{{ i + 1 }}</span>
                    </div>
                  </td>
                  <td class="pl-1">
                    <span
                      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >
                      {{ item.name }}
                    </span>
                  </td>
                  <td class="text-right">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.data }}
                    </span>
                    <span class="text-muted font-weight-bold">
                      {{ $t("GENERAL_TEXT.CASES") }}
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div
            v-if="limit < list.length"
            class="d-flex flex-center"
            id="kt_sticky_toolbar_chat_toggler_2"
            data-toggle="tooltip"
            title=""
            data-placement="right"
            data-original-title="Chat Example"
            aria-describedby="tooltip405474"
          >
            <button
              @click="setUnlimited"
              class="btn btn-hover-bg-primary btn-text-primary btn-hover-text-white border-0 font-weight-bold mr-2"
            >
              <i class="flaticon-refresh"></i>
              {{ $t("GENERAL_TEXT.LOAD_MORE_DATA") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_DASHBOARD_SUBMISSION_METHOD_CASE } from "@/core/services/store/dashboard.module";

export default {
  name: "SubmissionMethod",
  data() {
    return {
      list: [],
      limit: 5
    };
  },
  methods: {
    getList() {
      this.list = this.getSubmissionMethodCase;
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_DASHBOARD_SUBMISSION_METHOD_CASE) {
          this.list = this.getSubmissionMethodCase;
        }
      });
    },
    setUnlimited() {
      this.limit = 1000;
    }
  },
  mounted() {
    this.getList();
  },
  computed: mapGetters(["getSubmissionMethodCase"])
};
</script>

<style scoped></style>
