<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header">
      <div class="card-title">
        <span class="card-icon">
          <i class="flaticon2-line-chart text-message text-danger icon-2x"></i>
        </span>
        <h3 class="card-label">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t("DASHBOARD.YEARLY_CHART.TITLE") }}
          </span><br />
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            {{ $t("DASHBOARD.YEARLY_CHART.DESCRIPTION") }}
          </span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div class="text-center">
        <b-spinner
          variant="primary"
          label="Text Centered"
          v-if="!show"
        ></b-spinner>
      </div>
      <apexchart
        type="bar"
        :options="options"
        :series="series"
        v-if="show"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SET_DASHBOARD_YEARLY_CASE} from "@/core/services/store/dashboard.module";
export default {
  name: "YearlyChart",
  data() {
    return {
      sharedState: this.$store.state,
      show: false,
      options: {},
      series: []
    };
  },
  async beforeMount() {

  },
  methods: {
    getYearlyChart() {
      this.options = {
        chart: {
          id: "YearlyChart"
        },
        xaxis: {
          categories: this.getCategoriesYearlyCase,
          title: {
            text: this.$t("GENERAL_TEXT.YEAR")
          }
        },
        yaxis: {
          title: {
            text: this.$t("GENERAL_TEXT.CASE_COUNT")
          }
        },
        plotOptions: {
          bar: {
            distributed: true
          }
        }
      };
      this.series = [
        {
          name: this.$t("DASHBOARD.YEARLY_CHART.CASES_COUNT"),
          data: this.getSeriesYearlyCase
        }
      ];
      this.show = true;

      this.unsubscribe = this.$store.subscribe((mutation) => {
        this.show = false;
        if (mutation.type === SET_DASHBOARD_YEARLY_CASE) {
          this.options = {
            chart: {
              id: "YearlyChart"
            },
            xaxis: {
              categories: this.getCategoriesYearlyCase,
              title: {
                text: this.$t("GENERAL_TEXT.YEAR")
              }
            },
            yaxis: {
              title: {
                text: this.$t("GENERAL_TEXT.CASE_COUNT")
              }
            },
            plotOptions: {
              bar: {
                distributed: true
              }
            }
          };
          this.series = [
            {
              name: this.$t("DASHBOARD.YEARLY_CHART.CASES_COUNT"),
              data: this.getSeriesYearlyCase
            }
          ];
        }
        this.show = true;
      });
    }
  },
  created() {
  },
  mounted() {
    this.getYearlyChart();
  },
  computed: mapGetters([
    "getCategoriesYearlyCase",
    "getSeriesYearlyCase",
    "getSelectedProvinceName"
  ])
};
</script>
