<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 pt-5">
      <div class="card-title">
        <span class="card-icon">
          <i class="fa fa-map-marker-alt text-danger icon-2x"></i>
        </span>
        <h3 class="card-label">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t("DASHBOARD.MAPS.TITLE") }} </span
          ><br />
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            {{ $t("DASHBOARD.MAPS.DESCRIPTION") }}
          </span>
        </h3>
      </div>

      <div class="card-toolbar">
        <ul
          class="nav nav-pills nav-pills-sm nav-dark-75"
          v-if="!this.isAuthenticated"
        >
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              v-bind:class="isYearly ? '' : 'active'"
              data-toggle="tab"
              href="#"
              @click="monthlyData()"
            >
              {{ $t("DASHBOARD.MAPS.MONTHLY") }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              v-bind:class="isYearly ? 'active' : ''"
              data-toggle="tab"
              href="#"
              @click="yearlyData()"
            >
              {{ $t("DASHBOARD.MAPS.YEARLY") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="text-center">
        <b-spinner
          variant="primary"
          label="Text Centered"
          v-if="!show"
        ></b-spinner>
      </div>
      <l-map
        :zoom="zoom"
        :center="center"
        :style="mapStyle"
        v-if="show"
        class="leaflet-container leaflet-fade-anim leaflet-grab leaflet-touch-drag"
        ref="myMap"
        :options="{ scrollWheelZoom: false }"
      >
        <l-control :position="'bottomleft'">
          <b-card
            border-variant="primary"
            :header="legendHeader"
            header-bg-variant="primary"
            header-text-variant="white"
            align="center"
          >
            <b-row class="text-center">
              <b-col class="col-md-4">
                <b-row>
                  <b-col class="col-md-2">
                    <v-chip class="ma-2" color="#9E0E0B" label>
                      &nbsp;
                    </v-chip>
                  </b-col>
                  <b-col class="col-md-10">
                    <b-card-text class="text-left">
                      {{ $t("DASHBOARD.MAPS.LEGEND_1") }}
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="col-md-4">
                <b-row>
                  <b-col class="col-md-2">
                    <v-chip class="ma-2" color="#FFFC52" label>
                      &nbsp;
                    </v-chip>
                  </b-col>
                  <b-col class="col-md-10">
                    <b-card-text class="text-left">
                      {{ $t("DASHBOARD.MAPS.LEGEND_2") }}
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="col-md-4">
                <b-row>
                  <b-col class="col-md-2">
                    <v-chip class="ma-2" color="#55CC08" label>
                      &nbsp;
                    </v-chip>
                  </b-col>
                  <b-col class="col-md-10">
                    <b-card-text class="text-left">
                      {{ $t("DASHBOARD.MAPS.LEGEND_3") }}
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </l-control>
        <l-control :position="'topright'" class="custom-control-watermark">
          <b-card
            border-variant="primary"
            :header="totalCaseTitle"
            header-bg-variant="primary"
            header-text-variant="white"
            align="center"
          >
            <b-card-text
								class="font-size-lg font-weight-bold"
              >{{
                $t("DASHBOARD.MAPS.COUNT_MAP", { case1: this.getTotalCase })
              }}
              <b-button
                v-if="getSelectedProvince !== 0"
                v-on:click="resetProvince"
                >Reset</b-button
              >
            </b-card-text>
          </b-card>
        </l-control>
        <l-tile-layer :url="url" :attribution="attribution" />
        <l-geo-json
          v-if="geoShow"
          :geojson="geoJson"
          :options="options"
          :options-style="styleFunction"
          @update:center="centerUpdate"
          ref="myGeoJson"
        />
      </l-map>
    </div>
    <b-modal ref="kabkota-modal" hide-footer :title="provModal">
      <div class="d-block text-center">
        <h3>{{ $t("DASHBOARD.MAPS.STATE", { prov1: provModal }) }}</h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { LControl, LGeoJson, LMap, LTileLayer } from "vue2-leaflet";
import { SET_PROVINCE } from "@/core/services/store/province.module";
import { mapGetters } from "vuex";
import { latLng } from "leaflet";
import {
  GET_CASE_DETAIL,
  GET_CLAIMANT_CLASSIFICATION,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_MAPS_DATA,
  GET_DASHBOARD_MONTHLY_CASE,
  GET_DASHBOARD_TOTAL_CASE,
  GET_DASHBOARD_YEARLY_CASE,
  GET_DEFENDANT_CLASSIFICTION,
  GET_ISSUE_CASE,
  GET_LATEST_UPDATE,
  GET_RIGHT_CLASSIFICATION_CASE,
  GET_SPECIAL_EVENT_CASE,
  GET_SUBMISSION_METHOD_CASE,
  GET_VICTiM_CLASSIFICATION,
  RESET_FILTER_DASHBOARD,
  SET_DASHBOARD_TOTAL_CASE,
  SET_FILTER_DASHBOARD,
  SET_SELECTED_MONTH,
  SET_SELECTED_PROVINCE
} from "@/core/services/store/dashboard.module";
import { SET_LOADING } from "@/core/services/store/loading.module";

export default {
  name: "Maps",
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    LGeoJson,
    LControl
  },
  data() {
    return {
      mapStyle: this.isAuthenticated
        ? "height: 100%; width: 100%"
        : "height: 900px; width: 100%",
      loading: false,
      show: false,
      enableTooltip: true,
      zoom: 5,
      center: [-2.284551, 121.904297],
      currentCenter: [-2.284551, 121.904297],
      states: this.provinces,
      fillColor: "#F99",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: latLng(47.41322, -1.219482),
      geoJson: [],
      isYearly: true,
      geoShow: false,
      provinceData: this.$store.state.dashboard.caseDataByState,
      provModal: "",
      selectedProvince: this.getSelectedProvince,
      totalCaseTitle: this.$t("DASHBOARD.MAPS.TITLE_TOTAL_CASE", {
        prov: this.getSelectedProvinceName
      }),
      totalCase: this.$t("DASHBOARD.MAPS.COUNT_MAP", {
        case1: this.getTotalCase
      }),
      legendHeader: this.$t("DASHBOARD.MAPS.LEGEND_HEADER")
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      this.zoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
      this.center = center;
    },
    getLatLong() {
      return [-2.284551, 121.904297];
    },
    async yearlyData() {
      this.$store.dispatch(SET_LOADING, true);
      this.isYearly = true;
      this.geoShow = false;
      this.show = false;
      await this.$store.dispatch(SET_SELECTED_MONTH, 0);
      this.provinceData = this.$store.state.dashboard.caseDataByState;
      setTimeout(() => {
        this.geoShow = true;
        this.show = true;
      }, 2000);
      this.resetProvince();
      await this.$store.dispatch(SET_LOADING, false);
    },
    async monthlyData() {
      this.$store.dispatch(SET_LOADING, true);
      this.isYearly = false;
      this.show = false;
      this.geoShow = false;
      await this.$store.dispatch(SET_SELECTED_MONTH, new Date().getMonth() + 1);
      this.provinceData = this.$store.state.dashboard.caseDataByState;
      setTimeout(() => {
        this.geoShow = true;
        this.show = true;
      }, 2000);
      this.resetProvince();
      await this.$store.dispatch(SET_LOADING, false);
    },
    topFiveStates() {
      return this.provinceData.filter(function() {
        let i = 0;
        i++;
        return i >= 5;
      });
    },
    statesClick(state) {
      this.$store.dispatch(SET_LOADING, true);
      this.show = false;
      this.geoShow = false;
      let stateData = this.getDataByStateId(
        state.target.feature.properties.KODE
      );

      this.map = this.$refs.myMap.mapObject;
      this.map.fitBounds(state.target.getBounds());
      this.center = state.target.latlng;
      this.$store.dispatch(SET_SELECTED_PROVINCE, stateData.id ?? 0);
      this.totalCaseTitle = this.$t("DASHBOARD.MAPS.TITLE_TOTAL_CASE", {
        prov: this.getSelectedProvinceName
      });
      setTimeout(() => {
        this.geoShow = true;
        this.$store.dispatch(SET_LOADING, false);
      }, 1000);
      this.show = true;
    },
    setMaps(feature) {
      this.$store.dispatch(SET_LOADING, true);
      this.show = false;
      this.geoShow = false;
      // let stateData = this.getDataByStateId(
      //     feature.feature.properties.KODE
      // );

      this.map = this.$refs.myMap.mapObject;
      this.map.fitBounds(feature._bounds);
      this.center = feature.latlng;
      // this.$store.dispatch(SET_SELECTED_PROVINCE, stateData.id ?? 0);
      this.totalCaseTitle = this.$t("DASHBOARD.MAPS.TITLE_TOTAL_CASE", {
        prov: this.getSelectedProvinceName
      });
      setTimeout(() => {
        this.geoShow = true;
        this.$store.dispatch(SET_LOADING, false);
      }, 1000);
      this.show = true;
    },
    bindingData() {
      // console.log(geo.getAvailableFeatures());
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_SELECTED_PROVINCE) {
          this.show = false;
          this.geoShow = false;
          setTimeout(() => {
            this.geoShow = true;
          }, 1000);
          this.show = true;
        }
        if (mutation.type === SET_DASHBOARD_TOTAL_CASE) {
          this.totalCase = this.$t("DASHBOARD.MAPS.COUNT_MAP", {
            case1: this.getTotalCase
          });
        }
        if (
          mutation.type === SET_FILTER_DASHBOARD ||
          mutation.type === RESET_FILTER_DASHBOARD
        ) {
          this.$store.dispatch(SET_LOADING, true);
          this.$store.dispatch(GET_DASHBOARD_MAPS_DATA);
          this.$store.dispatch(GET_DASHBOARD_DATA);
          this.$store.dispatch(GET_DASHBOARD_TOTAL_CASE);
          this.$store.dispatch(GET_DASHBOARD_YEARLY_CASE);
          this.$store.dispatch(GET_RIGHT_CLASSIFICATION_CASE);
          this.$store.dispatch(GET_ISSUE_CASE);
          this.$store.dispatch(GET_CLAIMANT_CLASSIFICATION);
          this.$store.dispatch(GET_VICTiM_CLASSIFICATION);
          this.$store.dispatch(GET_DEFENDANT_CLASSIFICTION);
          this.$store.dispatch(GET_DASHBOARD_MONTHLY_CASE);
          this.$store.dispatch(GET_SUBMISSION_METHOD_CASE);
          this.$store.dispatch(GET_CASE_DETAIL);
          this.$store.dispatch(GET_SPECIAL_EVENT_CASE);
          this.$store.dispatch(GET_LATEST_UPDATE);
          this.show = false;
          this.geoShow = false;
          setTimeout(() => {
            this.geoShow = true;
            this.$store.dispatch(SET_LOADING, false);
          }, 1000);
          this.show = true;
        }
      });
    },
    resetProvince() {
      this.$store.dispatch(SET_LOADING, true);
      this.show = false;
      this.geoShow = false;
      this.$store.dispatch(SET_SELECTED_PROVINCE, 0);
      this.center = this.getLatLong();
      setTimeout(() => {
        this.geoShow = true;
        this.show = true;
        this.$store.dispatch(SET_LOADING, false);
      }, 2000);
      this.totalCaseTitle = this.$t("DASHBOARD.MAPS.TITLE_TOTAL_CASE", {
        prov: this.getSelectedProvinceName
      });
    }
  },
  computed: {
    ...mapGetters([
      "provinces",
      "getDataByStateId",
      "getSelectedYear",
      "getSelectedProvince",
      "getSelectedMonth",
      "getTotalCase",
      "getSelectedProvinceName",
      "isAuthenticated"
    ]),
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
        zoomControl: true,
        zoomSnap: false
      };
    },
    styleFunction() {
      return feature => {
        this.$store.dispatch(SET_LOADING, true);
        let selectedProvince = this.getSelectedProvince;
        let warna = "";
        if (selectedProvince === 0)
          warna = this.getDataByStateId(feature.properties.KODE).color;
        if (selectedProvince !== 0) {
          if (selectedProvince === feature.properties.KODE) {
            warna = this.getDataByStateId(feature.properties.KODE).color;
          } else {
            warna = "#FFF";
          }
        }
        this.$store.dispatch(SET_LOADING, false);
        return {
          weight: 0.1,
          color: "#000",
          opacity: 1,
          fillColor: warna,
          fillOpacity: 0.5
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        let data = this.getDataByStateId(feature.properties.KODE);
        // layer
        layer.bindTooltip(
          // "<div>" +
          //   this.$t("DASHBOARD.MAPS.STATE", { prov1: data.name }) +
          //   "</div>" +
          "<div>" +
            // this.$t("DASHBOARD.MAPS.COUNT", { case1: data.data }) +
            data.data +
            "</div>",
          {
            permanent: true,
            sticky: true,
            direction: "center",
            opacity: 0.7,
            pane: "tooltipPane"
          }
        );
        layer.on({
          click: param => {
            this.statesClick(param);
          }
        });
      };
    }
  },
  async created() {
    this.$store.dispatch(SET_LOADING, true);
    this.show = false;
    this.geoShow = false;
    const response = await fetch("/prov.geojson");
    this.geoJson = await response.json();
    this.geoShow = true;
    this.show = true;
    this.totalCaseTitle = this.$t("DASHBOARD.MAPS.TITLE_TOTAL_CASE", {
      prov: this.getSelectedProvinceName
    });
    this.$store.dispatch(SET_LOADING, false);
  },
  mounted() {
    this.show = true;
    this.geoShow = true;
    this.bindingData();
  },
  async beforeMount() {
    await this.$store.dispatch(SET_PROVINCE);
  }
};
</script>
