import { render, staticRenderFns } from "./RightClassification.vue?vue&type=template&id=791a43b0&scoped=true"
import script from "./RightClassification.vue?vue&type=script&lang=js"
export * from "./RightClassification.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "791a43b0",
  null
  
)

export default component.exports