<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header">
      <div class="card-title">
        <span class="card-icon">
          <i class="flaticon2-line-chart text-message text-danger icon-2x"></i>
        </span>
        <h3 class="card-label">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t("DASHBOARD.MONTHLY_CHART.TITLE") }} </span
          ><br />
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            {{
              $t("DASHBOARD.MONTHLY_CHART.DESCRIPTION", {
                monthlyChart1: this.getSelectedYear
              })
            }}
          </span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div class="text-center">
        <b-spinner
          variant="primary"
          label="Text Centered"
          v-if="!show"
        ></b-spinner>
      </div>
      <apexchart
        type="area"
        :options="options"
        :series="series"
        v-if="show"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { SET_DASHBOARD_MONTHLY_CASE } from "@/core/services/store/dashboard.module";
import { mapGetters } from "vuex";

export default {
  name: "MonthlyChart",
  data() {
    return {
      sharedState: this.$store.state,
      show: false,
      options: {},
      series: {}
    };
  },
  mounted() {
    this.getMonthlyChart();
  },
  methods: {
    getMonthlyChart() {
      this.options = {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: "straight"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: this.getCategoryMonthlyCase,
          title: {
            text: this.$t("DASHBOARD.MONTHLY_CHART.MONTH")
          }
        },
        yaxis: {
          title: {
            text: this.$t("DASHBOARD.MONTHLY_CHART.COUNT")
          }
        }
      };
      this.series = [
        {
          name: this.$t("DASHBOARD.YEARLY_CHART.CASES_COUNT"),
          data: this.getSeriesMonthlyCase
        }
      ];
      this.show = true;
      this.unsubscribe = this.$store.subscribe(mutation => {
        this.show = false;
        if (mutation.type === SET_DASHBOARD_MONTHLY_CASE) {
          this.options = {
            chart: {
              height: 350,
              type: "line",
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: true
            },
            stroke: {
              curve: "straight"
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5
              }
            },
            xaxis: {
              categories: this.getCategoryMonthlyCase,
              title: {
                text: this.$t("DASHBOARD.MONTHLY_CHART.MONTH")
              }
            },
            yaxis: {
              title: {
                text: this.$t("DASHBOARD.MONTHLY_CHART.COUNT")
              }
            }
          };
          this.series = [
            {
              name: this.$t("DASHBOARD.YEARLY_CHART.CASES_COUNT"),
              data: this.getSeriesMonthlyCase
            }
          ];
        }
        this.show = true;
      });
    }
  },
  computed: mapGetters([
    "getCategoryMonthlyCase",
    "getSeriesMonthlyCase",
    "getSelectedYear"
  ])
};
</script>
