<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--    <div class="card-header">-->
    <!--      <h3 class="card-title">-->
    <!--        Filter-->
    <!--      </h3>-->
    <!--    </div>-->
    <b-alert
      show
      variant="info"
      class="alert alert-custom alert-danger alert-shadow fade show gutter-b"
      v-if="showError1"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-white svg-icon-lg">
          <inline-svg src="/media/svg/icons/Home/Timer.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Kelahasan Pemilihan tanggal</b>
        Pemilihan tanggal awal dan akhir hanya dapat dilakukan pada bulan yang
        sama.
      </div>
    </b-alert>
    <b-alert
      show
      variant="info"
      class="alert alert-custom alert-danger alert-shadow fade show gutter-b"
      v-if="showError2"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-white svg-icon-lg">
          <inline-svg src="/media/svg/icons/Home/Timer.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Kelahasan Pemilihan tanggal</b>
        Tanggal akhir harus lebih besar dari tanggal awal.
      </div>
    </b-alert>
    <div class="card-body">
      <h5>{{ $t("DASHBOARD_FILTER.PERIOD") }}</h5>
      <div class="form-group">
        <b-form-select
          :options="months"
          v-model="selectedMonth"
          @change="countrySelect"
          class="mb-3"
          value-field="monthId"
          text-field="monthName"
          disabled-field="notEnabled"
          aria-placeholder="Bulan"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_MONTH")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <div class="form-group">
        <b-form-select
          :options="years"
          v-model="selectedYear"
          class="mb-3"
          value-field="yearId"
          text-field="yearName"
          disabled-field="notEnabled"
          aria-placeholder="Tahun"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_YEAR")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>

      <!--      <div class="form-group">-->
      <!--        <month-picker-input-->
      <!--          :lang="locale"-->
      <!--          @change="monthSelected"-->
      <!--        ></month-picker-input>-->
      <!--      </div>-->
      <h5>{{ $t("DASHBOARD_FILTER.LOCATION") }}</h5>
      <div class="form-group">
        <b-form-select
          :options="countries"
          v-model="countrySelected"
          @change="countrySelect"
          class="mb-3"
          value-field="id"
          text-field="name"
          disabled-field="notEnabled"
          aria-placeholder="Negara"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_COUNTRY")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <div class="form-group">
        <b-form-select
          v-model="selectedProvince"
          :options="provinces"
          @change="provinceSelect"
          class="mb-3"
          value-field="id"
          text-field="name"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_PROVINCE")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("DASHBOARD_FILTER.ISSUE") }}</h5>
      <div class="form-group">
        <b-form-select
          v-model="selectedIssue"
          :options="issues"
          @change="issueSelect"
          class="mb-3"
          value-field="issue_id"
          text-field="issue_name"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_ISU")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("DASHBOARD_FILTER.CASE_DETAIL") }}</h5>
      <div class="form-group">
        <b-form-select
          v-model="selectedCaseDetail"
          :options="caseDetails"
          class="mb-3"
          value-field="id"
          text-field="name"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_CASE_DETAIL")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("DASHBOARD_FILTER.RIGHT_CLASSIFICATION") }}</h5>
      <div class="form-group">
        <b-form-select
          v-model="selectedRight"
          :options="rights"
          class="mb-3"
          value-field="right_id"
          text-field="right_name"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_RIGHT")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("DASHBOARD_FILTER.VICTIM_CLASSIFICATION") }}</h5>
      <div class="form-group">
        <b-form-select
          v-model="selectedVictim"
          :options="victims"
          class="mb-3"
          value-field="victim_type_id"
          text-field="victim_type_name"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_VICTIM")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("DASHBOARD_FILTER.DEFENDANT_CLASSIFICATION") }}</h5>
      <div class="form-group">
        <b-form-select
          v-model="selectedDefendant"
          :options="defendantType"
          class="mb-3"
          value-field="complainant_type_id"
          text-field="complainant_type_name"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_DEFENDANT")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("DASHBOARD_FILTER.COMPLAINANT_CLASSIFICATION") }}</h5>
      <div class="form-group">
        <b-form-select
          v-model="selectedComplainantType"
          :options="complainantTypes"
          class="mb-3"
          value-field="complainant_type_id"
          text-field="complainant_type_name"
          disabled-field="notEnabled"
        >
          <template #first>
            <b-form-select-option :value="null">{{
              $t("DASHBOARD_FILTER.SELECT_COMPLAINANT")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <h5>{{ $t("DASHBOARD_FILTER.SPECIAL_EVENT") }}</h5>
      <div class="form-group">
        <b-form-select
          v-model="selectedSpecialEvent"
          :options="specialEvent"
          class="mb-3"
          value-field="id_peristiwa"
          text-field="nama_peristiwa"
          disabled-field="notEnabled"
        >
          <b-form-select-option :value="null">{{
            $t("DASHBOARD_FILTER.SELECT_SPECIAL_EVENT")
          }}</b-form-select-option>
        </b-form-select>
      </div>
      <div class="form-group row align-center">
        <div class="col-6 text-center">
          <button
            type="button"
            @click="filterDashboard"
            class="btn btn-light-primary font-weight-bold"
          >
            <i class="icon-xl la la-filter"></i> Filter
          </button>
        </div>
        <div class="col-6 text-center">
          <button
            @click="resetFilter"
            type="button"
            class="btn btn-light-danger font-weight-bold"
          >
            <i class="icon-xl la la-history"></i>Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ADD_COUNTRY,
  ADD_PROVINCE,
  SET_COUNTRY,
  SET_COUNTRY_SELECTED
} from "@/core/services/store/province.module";
import {
  GET_COMPLAINANT,
  GET_DEFENDANT,
  GET_ISSUE_DATA_LIST,
  GET_RIGHT,
  GET_SPECIAL_EVENT,
  GET_VICTIM,
  SET_COMPLAINANT,
  SET_DEFENDANT_TYPE,
  SET_DETAIL_CASES,
  SET_ISSUE_DATA_LIST,
  SET_ISSUE_SELECTED,
  SET_RIGHT,
  SET_RIGHT_SELECTED,
  SET_SPECIAL_EVENT,
  SET_VICTIM
} from "@/core/services/store/dashboard-filter.module";
import {
  RESET_FILTER_DASHBOARD,
  SET_FILTER_DASHBOARD,
  SET_SELECTED_PROVINCE
} from "@/core/services/store/dashboard.module";
import { SET_LOADING } from "@/core/services/store/loading.module";
// import {SET_SELECTED_PROVINCE} from "@/core/services/store/dashboard.module";

export default {
  name: "DashboardFilter",
  components: {},
  data() {
    return {
      locale: localStorage.getItem("language") || "id",
      month: null,
      year: null,
      validateDate: null,
      countrySelected: null,
      selectedProvince: null,
      selectedIssue: null,
      selectedCaseDetail: null,
      selectedRight: null,
      selectedVictim: null,
      selectedComplainantType: null,
      selectedDefendant: null,
      selectedSpecialEvent: null,
      selectedMonth: null,
      selectedYear: null,
      selected: "A",
      options: [
        { item: "A", name: "Option A" },
        { item: "B", name: "Option B" },
        { item: "D", name: "Option C", notEnabled: true },
        { item: { d: 1 }, name: "Option D" }
      ],
      provinces: [],
      countries: [],
      issues: [],
      caseDetails: [],
      rights: [],
      victims: [],
      complainantTypes: [],
      defendantType: [],
      specialEvent: [],
      months: [],
      years: [],
      minDate: new Date().setDate(1).toString(),
      maxDate: new Date().setDate(10).toString(),
      showError1: false,
      showError2: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_COUNTRY);
    this.$store.dispatch(GET_ISSUE_DATA_LIST);
    this.$store.dispatch(GET_RIGHT);
    this.$store.dispatch(GET_VICTIM);
    this.$store.dispatch(GET_COMPLAINANT);
    this.$store.dispatch(GET_DEFENDANT);
    this.$store.dispatch(GET_SPECIAL_EVENT);
    this.calculateMonths();
    this.calculateYear();
    this.bindingData();
  },
  methods: {
    bindingData() {
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === ADD_PROVINCE) {
          this.provinces = this.getProvinces;
        }
        if (mutation.type === ADD_COUNTRY) {
          this.countries = this.getCountries;
        }
        if (mutation.type === SET_ISSUE_DATA_LIST) {
          this.issues = this.getIssueData;
        }
        if (mutation.type === SET_DETAIL_CASES) {
          this.caseDetails = this.getCaseDetails;
        }
        if (mutation.type === SET_RIGHT) {
          this.rights = this.getRight;
        }
        if (mutation.type === SET_VICTIM) {
          this.victims = this.getVictim;
        }
        if (mutation.type === SET_COMPLAINANT) {
          this.complainantTypes = this.getComplainantType;
        }
        if (mutation.type === SET_DEFENDANT_TYPE) {
          this.defendantType = this.getDefendantType;
        }
        if (mutation.type === SET_SPECIAL_EVENT) {
          this.specialEvent = this.getSpecialEvent;
        }
      });
    },
    countrySelect(val) {
      this.$store.dispatch(SET_COUNTRY_SELECTED, val);
    },
    provinceSelect() {
      // console.log(val);
      // this.$store.dispatch(SET_SELECTED_PROVINCE, val);
    },
    issueSelect(val) {
      this.$store.dispatch(SET_ISSUE_SELECTED, val);
    },
    rightSelect(val) {
      this.$store.dispatch(SET_RIGHT_SELECTED, val);
    },
    filterDashboard() {
      this.$store.dispatch(SET_LOADING, true);
      this.month = this.selectedMonth ?? 0;
      this.year = this.selectedYear ?? new Date().getFullYear();
      let payload = {
        month: this.month,
        year: this.year,
        province: this.selectedProvince ?? 0,
        country: this.countrySelected,
        issue: this.selectedIssue,
        caseDetail: this.selectedCaseDetail,
        right: this.selectedRight,
        victim: this.selectedVictim,
        defendantType: this.selectedDefendant,
        complainantType: this.selectedComplainantType,
        specialEvent: this.selectedSpecialEvent
      };
      // console.log(payload);
      this.$store.dispatch(SET_FILTER_DASHBOARD, payload);
      this.$store.dispatch(SET_LOADING, false);
    },
    resetFilter() {
      this.$store.dispatch(SET_LOADING, true);
      this.month = 0;
      this.year = new Date().getFullYear();
      this.selectedProvince = null;
      this.selectedSpecialEvent = null;
      this.selectedComplainantType = null;
      this.selectedDefendant = null;
      this.selectedCaseDetail = null;
      this.countrySelected = null;
      this.selectedRight = null;
      this.selectedIssue = null;
      this.selectedVictim = null;
      this.selectedMonth = null;
      this.selectedYear = null;
      let payload = {
        month: this.month,
        year: this.year,
        province: this.selectedProvince,
        country: this.countrySelected,
        issue: this.selectedIssue,
        caseDetail: this.selectedCaseDetail,
        right: this.selectedRight,
        victim: this.selectedVictim,
        defendantType: this.selectedDefendant,
        complainantType: this.selectedComplainantType,
        specialEvent: this.selectedSpecialEvent
      };
      // console.log(payload);
      this.$store.dispatch(RESET_FILTER_DASHBOARD, payload);
      this.$store.dispatch(SET_SELECTED_PROVINCE, 0);
      this.$store.dispatch(SET_LOADING, false);
    },
    monthSelected(data) {
      this.month = data.monthIndex;
      this.year = data.year;
    },
    periodStartChanged() {
      // let maxDate = new Date(this.dateStart);
      // maxDate.setMonth(new Date(this.dateStart).getMonth() + 1);
      // maxDate.setDate(0);
      //
      // this.minDate.setMonth(new Date(this.dateStart).getMonth() + 1);
      // this.minDate.setDate(1);
      // this.maxDate.setMonth(new Date(this.dateStart).getMonth() + 1);
      // console.log("TBA");
    },
    calculateMonths() {
      for (let i = 1; i <= 12; i++) {
        let locale = localStorage.getItem("language") || "id";
        let year = this.selectedYear || new Date().getFullYear();
        let date = new Date(year, i - 1, 1);
        this.months.push({
          monthId: i,
          monthName: date.toLocaleString(locale, { month: "long" })
        });
      }
    },
    calculateYear() {
      let yearNow = new Date().getFullYear();
      for (let i = yearNow - 3; i <= yearNow; i++) {
        if (i >= 2020)
          this.years.push({
            yearName: i,
            yearId: i
          });
      }
    }
  },
  computed: mapGetters([
    "getProvinces",
    "getCountries",
    "getIssueData",
    "getCaseDetails",
    "getRight",
    "getVictim",
    "getComplainantType",
    "getDefendantType",
    "getSpecialEvent"
  ])
};
</script>
<style>
.month-picker__container {
  background: white;
}
.month-picker-input {
  width: 250px;
  font-size: 16px;
}
</style>
